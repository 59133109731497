import { API, graphqlOperation } from "aws-amplify";
import { getCalendars, getSchedules } from "graphql/queries";
import { updateSchedules, createSchedules } from "graphql/mutations";

export class CustomIdentityRequestWrapperProxy {
  constructor(config) {
    this.grantId = config.grantId;
    this.email = config.email;
    this.domain = config.domain;
    this.provider = config.provider;
    this.currentUserId = config.currentUserId;
  }

  _getBasePath(input) {
    const basePath = input.split("?")[0];
    return basePath.split("/").slice(0, 2).join("/");
  }

  _getLastPartOfURL(input) {
    const basePath = input.split("?")[0];
    const parts = basePath.split("/");
    return parts[parts.length - 1];
  }

  async request(args) {
    try {
      const { body, path, method } = args;
      let nylasData = {};

      // Get all calendars
      if (path.trim() === "calendars" && method.toLowerCase() === "get") {
        const { data } = await API.graphql(
          graphqlOperation(getCalendars, {
            input: this.grantId,
          })
        );

        nylasData = JSON.parse(data.getCalendars.result);
      }

      // Get all schedule configuration
      if (
        this._getBasePath(path.trim()) === "scheduling/configurations" &&
        method.toLowerCase() === "get"
      ) {
        const { data } = await API.graphql(
          graphqlOperation(getSchedules, {
            input: this.grantId,
          })
        );

        nylasData = JSON.parse(data.getSchedules.result);
      }

      // Update a schedule configuration
      if (
        this._getBasePath(path.trim()) === "scheduling/configurations" &&
        method.toLowerCase() === "put"
      ) {
        const { data } = await API.graphql(
          graphqlOperation(updateSchedules, {
            input: JSON.stringify({
              grantId: this.grantId,
              payload: body,
              method: method.toLowerCase(),
            }),
          })
        );

        nylasData = JSON.parse(data.updateSchedules.result);
      }

      // Create a schedule configuration
      if (
        this._getBasePath(path.trim()) === "scheduling/configurations" &&
        method.toLowerCase() === "post"
      ) {
        // Create schedule configuration
        const { data } = await API.graphql(
          graphqlOperation(createSchedules, {
            input: JSON.stringify({
              grantId: this.grantId,
              payload: body,
            }),
          })
        );

        nylasData = JSON.parse(data.createSchedules.result);
      }

      // Delete a schedule configuration
      if (
        this._getBasePath(path.trim()) === "scheduling/configurations" &&
        method.toLowerCase() === "delete"
      ) {
        const { data } = await API.graphql(
          graphqlOperation(updateSchedules, {
            input: JSON.stringify({
              grantId: this.grantId,
              payload: { id: this._getLastPartOfURL(path.trim()) },
              method: method.toLowerCase(),
            }),
          })
        );

        nylasData = JSON.parse(data.updateSchedules.result);
      }

      return [nylasData, null];
    } catch (error) {
      console.error("fetch error: ", error);
      return { error: "Error" };
    }
  }

  async currentUser() {
    return {
      id: this.grantId,
      email: this.email,
      provider: this.provider,
    };
  }
}
