import { useEffect, useState } from "react";
import { PERIODS } from "./periods";
import YellowBox from "../../shared/YellowBox";
import DefaultInput from "components/FormInputs/DefaultInput";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import classnames from "classnames";

const schema = Yup.object().shape({
  otherNoticePeriod: Yup.number()
    .transform((value, originalValue) => {
      return originalValue && value;
    })
    .nullable()
    .typeError("Please enter a valid number")
    .min(0, "Notice period cannot be negative")
    .max(90, "Notice period cannot exceed 90 days")
    .integer("Notice period must be a whole number")
    .notRequired(),
});

const NoticePeriod = ({
  className,
  noticePeriodRef,
  user,
  save,
  setDisabled,
  isCurrent,
  setReminderText,
}) => {
  const noticePeriodIsDefined =
    user?.noticePeriod % 15 === 0 && user?.noticePeriod <= 90;

  const [selectedNoticePeriod, setSelectedNoticePeriod] = useState(
    noticePeriodIsDefined ? user?.noticePeriod : "other"
  );

  useEffect(() => {
    if (!isCurrent || selectedNoticePeriod === "other") return;
    const isDisabledState = selectedNoticePeriod === null;
    setDisabled(isDisabledState);
    if (isDisabledState) {
      setReminderText("Select one option");
    } else {
      setReminderText("");
    }
  }, [selectedNoticePeriod, setDisabled, isCurrent, setReminderText]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      otherNoticePeriod: PERIODS.some(
        (period) => period.value === user?.noticePeriod
      )
        ? undefined
        : user?.noticePeriod || undefined,
    },
  });

  const {
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = methods;
  const otherValue = watch("otherNoticePeriod");

  useEffect(() => {
    if (selectedNoticePeriod !== "other") return;

    if (!otherValue || otherValue < 1) {
      setDisabled(true);
      setReminderText("Notice period must be a positive value");
    } else if (otherValue > 90) {
      setDisabled(true);
      setReminderText("Notice period cannot exceed 90 days");
    } else {
      setDisabled(false);
      setReminderText("");
    }
  }, [otherValue, selectedNoticePeriod, setReminderText, setDisabled]);

  const handleBlur = async () => {
    if (
      otherValue !== undefined &&
      otherValue !== "" &&
      otherValue !== user?.noticePeriod
    ) {
      await trigger("otherNoticePeriod");
      if (!errors.otherNoticePeriod) {
        const value = Math.floor(Math.abs(Number(otherValue)));
        save({ noticePeriod: value }, true);
        setSelectedNoticePeriod("other");
        setValue("otherNoticePeriod", value, { shouldValidate: true });
      }
    }
  };

  const handleYellowBoxClick = async (value) => {
    if (value === "other") {
      setDisabled(!otherValue);
      setSelectedNoticePeriod("other");
    } else if (value !== selectedNoticePeriod) {
      setSelectedNoticePeriod(value);
      save({ noticePeriod: value }, true);
    }
  };

  return (
    <div
      className={classnames("flex flex-col items-start gap-8", className)}
      ref={noticePeriodRef}
    >
      <div className="b1">When is the soonest you could start work?</div>
      <div className="flex gap-4 sm:gap-6 justify-start flex-wrap">
        {PERIODS.map((period, index) => (
          <YellowBox
            key={index}
            handleClick={() => handleYellowBoxClick(period.value)}
            selected={
              selectedNoticePeriod === period.value &&
              selectedNoticePeriod !== "other"
            }
          >
            {period.label}
          </YellowBox>
        ))}
        <YellowBox
          handleClick={() => handleYellowBoxClick("other")}
          selected={selectedNoticePeriod === "other"}
        >
          Other
        </YellowBox>
      </div>

      <FormProvider {...methods}>
        <form
          className={classnames("sm:w-[288px] w-full flex justify-between", {
            hidden: selectedNoticePeriod !== "other",
          })}
          onBlur={handleBlur}
        >
          <Controller
            name="otherNoticePeriod"
            control={methods.control}
            render={({ field }) => (
              <DefaultInput
                {...field}
                type="number"
                className="w-full"
                placeholder="Enter in days"
                id="otherNoticePeriod"
                label="Other"
                min="0"
                step="1"
                onKeyDown={(e) => {
                  if (e.key === "," || e.key === ".") {
                    e.preventDefault();
                  }
                }}
              />
            )}
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default NoticePeriod;
