import ResumeUpload from "../ProfessionalProfile/Resume";
import { useMemo, useEffect } from "react";

const bodyText = {
  firstUpload: {
    header: "Let's start with your resume",
    body: "Upload your resume and we'll automatically populate your profile, including skills and work experience, to increase your chances of getting hired.",
    subtext: "(or click skip to manually complete your profile instead)",
  },

  hasUploaded: {
    header: "Have an updated resume?",
    body: "If your resume has changed, upload the latest version and we'll update the skills and work experience in your profile to boost your chances of getting hired.",
    subtext: "(or just continue to update your profile manually instead)",
  },
};

const ResumeUploadPage = ({ user, save, setDisabled }) => {
  const firstResumeUpload = useMemo(() => {
    return !user.resumeLocation;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstResumeUpload) {
      setDisabled(true);
    }
  }, [firstResumeUpload, setDisabled]);

  const bodyTextIndex = firstResumeUpload ? "firstUpload" : "hasUploaded";

  return (
    <>
      <section className="pt-9 md:pt-0 compact-text container-large flex flex-col">
        <h1 className="h2">{bodyText[bodyTextIndex].header}</h1>
        <div className="flex items-baseline gap-4 mt-4">
          <div className="b2  max-w-3xl">{bodyText[bodyTextIndex].body}</div>
        </div>
        <div className="flex items-baseline gap-4 mt-1">
          <div className="b4  max-w-3xl">{bodyText[bodyTextIndex].subtext}</div>
        </div>
        <div className="mt-4 mb-48">
          <ResumeUpload
            user={user}
            save={save}
            hideHeader
            setDisabled={setDisabled}
          />
        </div>
      </section>
    </>
  );
};

export default ResumeUploadPage;
