import React, { useEffect } from "react";
import { useAuth } from "GlobalAuthContext";
import Scheduler from "./nylas-react.client";
import { CustomIdentityRequestWrapperProxy } from "./scheduler.identity";

const ScheduleEditorModal = ({ editToken }) => {
  const user = useAuth()?.user;
  const NYLAS_VERSION = process.env.REACT_APP_NYLAS_VERSION || "V2";
  useEffect(() => {
    if (editToken && NYLAS_VERSION === "V2") {
      window.nylas.scheduler.show({
        auth: {
          pageEditToken: editToken,
        },
        behavior: {
          disableEditing: ["slug"],
          displayOnly: ["event-info", "opening-hours", "calendars"],
        },
      });
    }
  }, [editToken, NYLAS_VERSION]);

  if (editToken && NYLAS_VERSION === "V3") {
    const domain = process.env.REACT_APP_NYLAS_API_URL_V3;
    const proxyDomain = process.env.REACT_APP_NYLAS_PROXY_URL;
    const clientId = process.env.REACT_APP_NYLAS_CLIENT_ID_V3;
    const currentPage = window.location.href;

    const props = () => {
      let nylasApiRequest = undefined;

      nylasApiRequest = new CustomIdentityRequestWrapperProxy({
        email: user?.email,
        grantId: user?.nylasAccountId,
        domain: proxyDomain,
        provider: "google",
        currentUserId: user?.id,
      });

      return {
        requiresSlug: user?.slugScheduler,
        schedulerPreviewLink: `https://book.nylas.com/us/${clientId}/{slug}`,
        ...(nylasApiRequest && { nylasApiRequest }),
      };
    };

    return (
      <div>
        <Scheduler.NylasSchedulerEditor
          mode="app"
          nylasSessionsConfig={{
            clientId: clientId,
            redirectUri: currentPage,
            domain: domain,
            hosted: true,
            accessType: "offline",
          }}
          defaultSchedulerConfigState={{
            selectedConfiguration: {
              requires_session_auth: false,
            },
          }}
          eventOverrides={{}}
          {...props()}
        ></Scheduler.NylasSchedulerEditor>
      </div>
    );
  }

  return <></>;
};

export default ScheduleEditorModal;
