import { SALARY_CONFIG } from "lookup";
import * as Yup from "yup";

export const isValidRate = (value) => {
  if (!value) {
    return true;
  }

  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false;
  }
  if (value && value > 0 && value <= SALARY_CONFIG.MAX_RATE_PER_HOUR) {
    return true;
  }
  return false;
};

export const isValidSalary = (value) => {
  if (!value) {
    return true;
  }
  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false;
  }
  if (value && value > 0 && value <= SALARY_CONFIG.MAX_SALARY) {
    return true;
  }
  return false;
};

export const getRateSchema = (
  { currentCurrency, currencies },
  compensationType
) => {
  const schemaShape = {
    ratePerHour: Yup.number()
      // To prevent showing an error about not casting the data type properly
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .max(
        SALARY_CONFIG.MAX_RATE_PER_HOUR,
        "Enter a value less than or equal to $200"
      )
      .min(1, "Enter at least $1")
      .test("integer", "Please enter an integer", isValidRate),
    salary: Yup.number()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .max(
        SALARY_CONFIG.MAX_SALARY,
        "Enter a value less than or equal to $416,000"
      )
      .min(1, "Enter at least $1"),
  };

  if (currentCurrency.value !== "USD") {
    let maxValueStr = "";
    let maxValue = 200;

    const currencySymbol = currencies[currentCurrency.value]?.code || "";

    if (compensationType === "salary") {
      maxValue = parseInt(SALARY_CONFIG.MAX_SALARY / currentCurrency.factor);

      maxValueStr = `Enter a value less than or equal to ${currencySymbol} ${maxValue} ($416,000)`;
    } else {
      maxValue = parseInt(
        SALARY_CONFIG.MAX_RATE_PER_HOUR / currentCurrency.factor
      );

      maxValueStr = `Enter a value less than or equal to ${currencySymbol} ${maxValue} ($200)`;
    }

    schemaShape.currencyInputValue = Yup.number()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .max(maxValue, maxValueStr)
      .min(1, `Enter at least ${currencySymbol}1`);
  }

  return Yup.object().shape(schemaShape);
};
