import classNames from "classnames";
import Button from "components/FormComponents/Button";
import Calendar from "images/new/calendar_icon.png";
import { useAuth } from "GlobalAuthContext";
// import Nylas from "nylas";

const ScheduleLinkButton = ({
  googleOnly = false,
  errorMessage = "",
  className = "",
  redirectURL, // URL to which platform redirects to after nylas auth flow
  nylasVersion,
}) => {
  const auth = useAuth();

  const getNylasLoginUrl = () => {
    let baseURL = "https://api.nylas.com";
    let clientId = process.env.REACT_APP_NYLAS_CLIENT_ID;
    let redirectUri = process.env.REACT_APP_NYLAS_REDIRECT_URI; // Redirect lambda URL which is added in Nylas V2 dashboard
    const SCOPES = `calendar`;
    const state = `${auth?.user?.username}${
      redirectURL ? `redirectURL=${encodeURIComponent(redirectURL)}` : ""
    }`;

    if (nylasVersion === "V3") {
      clientId = process.env.REACT_APP_NYLAS_CLIENT_ID_V3;
      redirectUri = process.env.REACT_APP_NYLAS_REDIRECT_URI_V3; // Redirect lambda URL which is added in Nylas V3 dashboard
      baseURL = "https://api.us.nylas.com";
      const accessType = "offline"; // To get refresh token

      return `${baseURL}/v3/connect/auth?client_id=${clientId}&redirect_uri=${redirectUri}&access_type=${accessType}&response_type=code&provider=google&state=${state}`;
    }

    return `${baseURL}/oauth/authorize?client_id=${clientId}&login_hint=${encodeURIComponent(
      auth?.user?.email
    )}&scopes=${SCOPES}&state=${state}&response_type=code&redirect_uri=${redirectUri}`;
  };

  const handleRedirectToNylas = () => {
    const nylasUrl = getNylasLoginUrl();
    window.location = nylasUrl;
  };
  return (
    <>
      {googleOnly ? (
        <Button
          className={classNames(
            "mb-5 flex items-center gap-2 w-64 min-w-0",
            className
          )}
          bgColor="white"
          onClick={handleRedirectToNylas}
        >
          <img src={Calendar} alt="calendar" className="w-10" />
          <div>Connect with Google Calendar</div>
        </Button>
      ) : (
        <Button
          className={classNames("mb-5", className)}
          onClick={handleRedirectToNylas}
        >
          Link Calendar
        </Button>
      )}
      {errorMessage && <p className="text-red-500 mb-2">{errorMessage}</p>}
    </>
  );
};

export default ScheduleLinkButton;
