import { CURRENCY_CONFIG } from "lookup";
import { CurrencyAPI } from "services/currency";

let currencyApi;

export const getCurrencyMappedOptions = (currencies) => {
  return Object.values(currencies).map(({ name, code, factor }) => {
    return {
      value: code,
      label: `${code} (${name})`,
      factor,
    };
  });
};

export const baseToTargetCurrency = (
  baseValue,
  conversionCurrencyValue,
  isInteger = true
) => {
  if (isInteger) {
    return parseInt(Math.floor(baseValue / conversionCurrencyValue).toFixed(2));
  }

  return Number((baseValue / conversionCurrencyValue).toFixed(2));
};

export const getCurrencyApiClient = () => {
  if (currencyApi) {
    return currencyApi;
  }

  currencyApi = new CurrencyAPI(CURRENCY_CONFIG.url, CURRENCY_CONFIG.apiKey);

  return currencyApi;
};

export const getApiCurrencies = async () => {
  try {
    const currencyApiClient = getCurrencyApiClient();

    const { data } = await currencyApiClient.currencies({});

    return data || {};
  } catch (error) {
    console.log("error", error);
  }

  return {};
};

export const getINRDefaultCurrency = (CURRENCY_CONFIG) => {
  return {
    value: CURRENCY_CONFIG.currencyCode || "INR",
    label: CURRENCY_CONFIG.currencyLabel || "INR (Indian Rupee)",
    factor: CURRENCY_CONFIG.exchangeFactor,
  };
};

export const getInitialCurrencies = (exchangeFactor) => {
  return {
    INR: {
      name: "Indian Rupee",
      code: "INR",
      factor: exchangeFactor,
    },
    USD: {
      name: "US Dollar",
      code: "USD",
      factor: 1,
    },
  };
};
