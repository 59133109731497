import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

import { useAuthDispatch } from "GlobalAuthContext";
import { GLOBAL_AUTH_ACTION_TYPES, ARTICLES, USER_TYPES } from "lookup";

import SocialShare from "components/SocialShare";
import SkillsAssessmentsWidget from "components/SkillsAssessmentsWidget";
import JobWidgetsWrapper from "components/JobWidgetsWrapper";
import ReferralsWidget from "components/ReferralsWidget";
import CodealikeWidget from "components/CodealikeWidget";
import Articles from "components/Articles";

import { HeaderSection } from "./molecules/HeaderSection";

import styles from "./index.module.css";

export const FreelancerDashboard = ({ appsyncUser }) => {
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const dispatchUserUpdated = (user) => {
    dispatch({
      type: GLOBAL_AUTH_ACTION_TYPES.USER_UPDATED,
      user,
    });
  };

  if (
    appsyncUser.profileCompletion < 100 &&
    localStorage.getItem("profileCompletion") !== "skipped"
  ) {
    history.push("profile/wizard/0");
    return null;
  }

  return (
    <div>
      <HeaderSection
        appsyncUser={appsyncUser}
        showProfileCompletionWidget={
          appsyncUser.userType === USER_TYPES.FREELANCER ||
          appsyncUser.userType === USER_TYPES.TORC_RECRUITER
        }
      />
      <section
        className={classNames(
          "relative overflow-hidden flex justify-center items-center flex-col md:flex-row px-6 py-24 pt-32 md:py-32",
          "full-width bg-dawn-grad",
          styles["freelancer-dashboard-body"]
        )}
      >
        <div
          className={classNames(
            "flex flex-col px-0 md:px-8 lg:px-14 tracking-wider gap-y-8 justify-center items-start w-full"
          )}
        >
          <a
            href="https://www.torc.dev/newsroom"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="mb-24"
              src={require(`images/banners/torc+randstad_banner.png`)}
              alt="banner"
            />
          </a>
          <SkillsAssessmentsWidget appsyncUser={appsyncUser} />
          <JobWidgetsWrapper
            condensed
            userSkillsNames={appsyncUser.skills?.map(({ name }) => name)}
            showSeeAllJobsLinkButton
          />
          <ReferralsWidget />
          <CodealikeWidget
            appsyncUser={appsyncUser}
            dispatchUserUpdated={dispatchUserUpdated}
          />

          <div className="flex flex-col relative w-full p-4">
            <h5 className="font-nexa mb-7">Featured Content</h5>
            <Articles articles={ARTICLES} />
          </div>
        </div>
      </section>
      <section
        className={classNames(
          "flex flex-col items-center justify-center py-16"
        )}
      >
        <div className="mb-4">
          <h2 className="text-3xl mb-6 font-nexa tracking-wider text-electricBlue-700">
            What's happening at Torc?
          </h2>
          <p className="font-rubik-regular text-xl tracking-wider">
            Hey {appsyncUser.given_name}. Thanks for your early support of Torc.
            We have been hard at work building Torc to be the best spot for you
            to find your next opportunity. Please make sure to join our{" "}
            <a
              href="https://www.torc.dev/discord"
              target="_blank"
              rel="noreferrer"
              alt="link to register at our Discord server"
            >
              Discord
            </a>{" "}
            and connect your account to your Discord user! We use Discord to get
            more feedback from you and, in addition to this space, to share
            updates and progress with you!
          </p>
          <p className="font-rubik-regular text-xl tracking-wider mt-6">
            Check out our new profiles! Let us know what you think about them!
            We are always looking for feedback! We want to build the best
            developer profiles and we need your thoughts and opinions!
          </p>
          <div className="tracking-wider mt-6">
            <p className="font-rubik-regular text-xl">
              Here's a sneak peak at what's new and coming soon:
            </p>
            <ul className="mt-3 list-disc ml-16">
              <li className="font-rubik-regular text-xl">
                Link your{" "}
                <a
                  href="https://www.torc.dev/discord"
                  target="_blank"
                  rel="noreferrer"
                  alt="link to register at our Discord server"
                  className="font-rubik-regular text-electricBlue-700 font-bold hover:underline"
                >
                  Discord
                </a>{" "}
                account to get early access to jobs and communicate with our
                community!
              </li>
              <li className="font-rubik-regular text-xl">
                Revamped referral program with more ways to earn including $500
                for you and $250 for your referral(see below)
              </li>
              <li className="font-rubik-regular text-xl">
                Link your calendar when you are matched with a job to make it
                easy to schedule interviews!
              </li>
              <li className="font-rubik-regular text-xl">
                Complete skills assessments and coming soon self service skills
                assessments!
              </li>
              <li className="font-rubik-regular text-xl">And much more!</li>
            </ul>
          </div>
        </div>
      </section>
      <section className={classNames("flex flex-col justify-center pb-16")}>
        <div className="mb-8">
          <p className="text-3xl mb-6 tracking-wider text-electricBlue-700">
            Have you heard about our{" "}
            <a
              className="font-rubik-regular text-3xl mb-6 font-bold tracking-wider text-electricBlue-700 hover:underline"
              href="https://www.torc.dev/talent/referrals"
              target="_blank"
              rel="noreferrer"
              alt="link to our referral program page"
            >
              referral program
            </a>
            ?
          </p>
          <p className="font-rubik-regular text-xl tracking-wider ">
            The great thing about a marketplace is it keeps getting stronger and
            stronger when everyone is successful—more jobs leads to more
            developers, and more developers means you're able to find a great
            match, faster. Read all about our{" "}
            <a
              className="font-rubik-regular text-electricBlue-700 font-bold hover:underline"
              href="https://www.torc.dev/talent/referrals"
              target="_blank"
              rel="noreferrer"
              alt="link to our referral program page"
            >
              referral program
            </a>
            !
          </p>
          <p className="font-rubik-regular text-xl tracking-wider mt-6">
            We're ready to put our money where our mouth is. If you refer a
            developer or a customer to Torc, we'll give you $500 and your
            referral $250 dollars once they are staffed or sign up as a
            customer!
          </p>
          <p className="font-rubik-regular text-xl tracking-wider mt-6">
            To be eligible for this revenue share, the new developer or customer
            must sign up using your personalized referral code.
          </p>
        </div>
        <SocialShare
          referralCode={appsyncUser.referralCode}
          referralCount={appsyncUser.referralCount}
          userType={appsyncUser.userType}
        />
      </section>
    </div>
  );
};
