import React from "react";
import Select, { components } from "react-select";

import "currency-flags/dist/currency-flags.min.css";

const CurrencySelect = ({
  options,
  defaultValue,
  onChange,
  isLoading,
  disabled,
}) => {
  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center gap-x-2">
          <div
            className={`currency-flag currency-flag-${props.data?.value?.toLowerCase()}`}
          />
          {props.label}
        </div>
      </components.Option>
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <div className="flex pl-2 w-full items-center">
          <div
            className={`currency-flag currency-flag-${props
              .getValue()[0]
              .value?.toLowerCase()}`}
          />
          {children}
        </div>
      </components.Control>
    );
  };

  return (
    <div className="my-2 flex items-center w-full">
      <div
        className="-m-0.5 p-0.5 rounded w-full"
        style={{
          background: `linear-gradient(to right, #83D9BB, #F4D675)`,
        }}
      >
        <div className="bg-white rounded w-full relative">
          <Select
            options={options}
            isLoading={isLoading}
            isDisabled={disabled}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder="Search for currency..."
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
                boxShadow: "none",
                padding: "0px 4px",
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: "250px",
              }),
            }}
            components={{ Option, Control }}
          />
        </div>
      </div>
    </div>
  );
};

export default CurrencySelect;
