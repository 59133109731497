import {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { Icon } from "semantic-ui-react";

const messageTypeColors = {
  warning: "#eec739",
  success: "#3a9960",
  error: "#fb7185",
};

const iconTypes = {
  success: "check",
  warning: "warning sign",
  error: "warning",
};
const ToastMessageContext = createContext();

const ToastModal = ({ flashMessage, clearFlashMessage }) => {
  const { message, type = "success", visible, duration } = flashMessage;
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (message) {
      setOpacity(1);
      const timeout = setTimeout(() => {
        setOpacity(0);
        setTimeout(() => {
          clearFlashMessage();
        }, 1000);
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [message, duration, clearFlashMessage]);

  if (!visible) {
    return null;
  }

  return createPortal(
    <div
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      className={`fixed sm:top-36 top-8 left-[50%] -translate-x-1/2 max-w-xs w-fit rounded-[12px] text-base p-4 text-white font-bold`}
      style={{
        backgroundColor: messageTypeColors[type],
        transition: "opacity 1s ease-in-out",
        opacity,
      }}
    >
      <div className="flex items-center">
        <p>{message}</p>
        <Icon name={iconTypes[type]} className="pl-3" size="large" />
      </div>
    </div>,
    document.body
  );
};

const ToastMessageProvider = ({ children }) => {
  const [message, setMessage] = useState({
    visible: false,
    message: "",
    type: "",
    time: 3000,
  });

  const flashMessage = useCallback(
    ({ message, type = "success", duration }) => {
      const messageDuration = (duration || 3) * 1000;
      setMessage({
        message,
        type,
        visible: true,
        duration: messageDuration,
      });
    },
    []
  );

  const clearFlashMessage = useCallback(() => {
    setMessage({ message: "", visible: false, type: "" });
  }, []);

  const values = { flashMessage };
  return (
    <ToastMessageContext.Provider value={values}>
      <ToastModal
        flashMessage={message}
        clearFlashMessage={clearFlashMessage}
      />
      {children}
    </ToastMessageContext.Provider>
  );
};

export const useToastMessage = () => useContext(ToastMessageContext);

export default ToastMessageProvider;
