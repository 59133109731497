import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../../../images/close-icon-blue.svg";
import backArrow from "images/backArrow.svg";
import { useAuth } from "GlobalAuthContext";
import { NylasScheduling } from "@nylas/react";

const NylasV3Scheduler = ({ user, applicant, jobOpp }) => {
  const clientId = process.env.REACT_APP_NYLAS_CLIENT_ID_V3;
  return (
    <NylasScheduling
      mode="app"
      slug={applicant?.slugScheduler}
      nylasBranding={false}
      clientId={clientId}
      bookingInfo={{
        primaryParticipant: {
          name: `${user.given_name} ${user.family_name}`,
          email: user.email,
        },
        additionalFields: {
          job: { value: `${jobOpp.title} ${jobOpp.id}`, type: "text" },
          metadata: { value: `${jobOpp.id}`, type: "metadata" },
        },
      }}
    />
  );
};

const NylasV2Scheduler = ({ user, applicant, jobOpp }) => {
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    const currentIframeRef = iframeRef?.current;
    currentIframeRef?.addEventListener("load", () => setIsLoading(false));
    return () => {
      currentIframeRef?.removeEventListener("load", () => setIsLoading(false));
    };
  }, [iframeRef]);

  const widgetLink = `https://schedule.nylas.com/${
    applicant?.slugScheduler
  }?&email=${encodeURIComponent(
    user.email
  )}&email_readonly=true&job=${encodeURIComponent(
    `${jobOpp.title} ${jobOpp.id}`
  )}&job_readonly=true&jobId=${jobOpp.id}`;

  return (
    <>
      {isLoading && (
        <div className="flex absolute inset-0 justify-center items-center">
          <span className="loader"></span>
        </div>
      )}
      <iframe
        ref={iframeRef}
        src={widgetLink}
        className="w-90 lg:w-[750px] h-[87vh]"
        title="Scheduler"
      />
    </>
  );
};

const SchedulerModal = ({ onBack, onClose, applicant, jobOpp }) => {
  const user = useAuth()?.user;
  const NYLAS_VERSION = process.env.REACT_APP_NYLAS_VERSION || "V2";

  return (
    <>
      <div className="h-full flex flex-col justify-center items-center bg-slate-50 pt-0 px-4">
        <div className="z-50 absolute top-2 right-3">
          <img
            src={closeIcon}
            className="cursor-pointer"
            alt="close"
            onClick={() => onClose("SchedulerModal")}
          />
        </div>
        {NYLAS_VERSION === "V3" ? (
          <NylasV3Scheduler user={user} jobOpp={jobOpp} applicant={applicant} />
        ) : (
          <NylasV2Scheduler user={user} jobOpp={jobOpp} applicant={applicant} />
        )}
      </div>
      <div className="border-t border-gray-600 w-full pt-2 pb-2">
        <div className="flex justify-between font-rubik font-bold w-full">
          <button
            className="sml:ml-12 ml-4 font-bold sm:text-sm text-xs border-0 rounded shadow-sm flex items-center justify-center py-2 px-3"
            onClick={onBack}
          >
            <img alt="back arrow" src={backArrow} className="w-3 h-3 mr-2" />
            BACK
          </button>
        </div>
      </div>
    </>
  );
};

export default SchedulerModal;
