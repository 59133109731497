import classNames from "classnames";

const ImageButton = ({
  logo,
  selected = false,
  handleClick,
  text,
  className,
  greedyText,
}) => {
  return (
    <div
      className={classNames(
        "md:max-w-[250px] py-3 px-[3vw] sm:px-5 md:p-4 w-full md:w-auto rounded-sm flex justify-between gap-2 sm:gap-3 md:gap-4 cursor-pointer opacity-70 items-center sm:hover:opacity-100 sm:hover:text-brandSecondary-1000 font-rubik-regular text-black md:leading-7 tracking-[-0.69px] text-sm sm:text-base md:text-xl border-2 sm:hover:border-brandSecondary-1000",
        {
          "!opacity-100 text-brandSecondary-1000 border-brandSecondary-1000 font-bold":
            selected,
        },
        className
      )}
      onClick={handleClick}
    >
      <div className="flex justify-center items-center xs:flex-shrink-0 max-w-[40%] h-[40px] md:h-[68px]">
        {logo}
      </div>
      <div
        className={classNames("flex items-center w-full", {
          "md:w-min": !greedyText,
        })}
      >
        {text}
      </div>
    </div>
  );
};

export default ImageButton;
