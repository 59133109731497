import { useCallback, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { onUserResumeDataExtraction } from "graphql/subscriptions";
import { getMe } from "graphql/queries";
import { useAuth, useAuthDispatch } from "GlobalAuthContext";
import { GLOBAL_AUTH_ACTION_TYPES } from "lookup";
import { createPortal } from "react-dom";

import logo from "../../../../images/TorcLogo.svg";
import { useToastMessage } from "ToastMessageContext";

const ResumeParsingModal = ({ open, onClose }) => {
  const { user } = useAuth();
  const dispatch = useAuthDispatch();
  const { flashMessage } = useToastMessage();

  const subscription = useRef();

  const updateUsersForm = useCallback(async () => {
    const userData = await API.graphql(graphqlOperation(getMe));

    if (!userData?.data?.getMe) {
      flashMessage({
        message: "An error occurred while parsing your resume",
        type: "error",
      });
      onClose();
      return;
    }

    const user = userData.data.getMe;
    dispatch({
      type: GLOBAL_AUTH_ACTION_TYPES.USER_UPDATED,
      user,
    });
  }, [dispatch, onClose, flashMessage]);

  useEffect(() => {
    let timeout = null;
    if (open) {
      timeout = setTimeout(() => {
        if (open) {
          onClose();
        }
      }, 2 * 60 * 1000);
      (async () => {
        if (subscription.current) {
          return;
        }
        const notificationSubscription = await API.graphql(
          graphqlOperation(onUserResumeDataExtraction, {
            identity_username: user.identity_username,
          })
        );

        if (notificationSubscription) {
          const sub = notificationSubscription.subscribe({
            next: ({ value }) => {
              const message = value?.data?.onUserResumeDataExtraction?.data;

              try {
                const data = JSON.parse(message);
                if (data.status === "resume_parse_process_successful") {
                  if (subscription.current) {
                    subscription.current.unsubscribe();
                    subscription.current = null;
                  }
                  updateUsersForm();
                  flashMessage({
                    message: "Profile successfully auto-filled",
                    type: "success",
                  });
                  onClose();
                }

                if (data.status === "error") {
                  if (subscription.current) {
                    subscription.current.unsubscribe();
                    subscription.current = null;
                  }
                  flashMessage({
                    message:
                      "An Error occurred while auto-filling your profile.",
                    type: "error",
                    duration: 5,
                  });
                  onClose();
                }
              } catch (e) {
                flashMessage({
                  message: "An error occurred while parsing your resume",
                  type: "error",
                });
                onClose();
                console.log(`Notification failed: ${message}`);
                console.error(e);
              }
            },
            error: (error) => console.log("subscribe error", error),
          });
          subscription.current = sub;
        }
      })();
    }
    return () => {
      if (subscription.current && subscription.current.unsubscribe) {
        subscription.current.unsubscribe();
        subscription.current = null;
      }

      if (timeout) {
        clearTimeout(timeout);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open) {
    return null;
  }

  return createPortal(
    <div
      className="fixed inset-0 grid place-items-center z-2000"
      style={{
        background: "rgba(255, 255, 255, 0.9)",
        height: "100%",
        width: "100%",
      }}
    >
      <section className="w-full h-full overflow-y-auto flex flex-col justify-center items-center">
        <div
          className="rounded-full w-20 h-20 bg-white p-1"
          style={{
            boxShadow: "0 10px 20px -5px rgba(0, 0, 0, 0.2)",
          }}
        >
          <img
            className="w-full h-full rounded-full animate-logoSpin"
            style={{
              animationTimingFunction: "ease-in-out",
            }}
            src={logo}
            aria-hidden
            alt="torc logo"
          />
        </div>
        <p className="font-bold text-2xl text-center max-w-lg flex mt-4 pl-5">
          Auto-filling your profile{" "}
          <span className="after:animate-dots ml-0 w-5 text-left" />
        </p>

        <p className="mt-3 text-lg text-center">
          You'll be able to edit the info afterward
        </p>
      </section>
    </div>,
    document.body
  );
};

export default ResumeParsingModal;
