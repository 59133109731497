const NumberDisplay = ({ number, label, className }) => {
  const [integerPart, decimalPart] = number.toString().split(".");

  return (
    <div className={className}>
      <div className="relative w-full">
        <label
          className={`text-xs font-bold sm:b4-bold block text-black pr-2 absolute top-[-10px] bg-white capitalize`}
        >
          {label}
        </label>

        <div className="b4 h-14 font-semibold placeholder-black text-black focus:border-brandTerciary-300 border-gray-600 appearance-none rounded-xs w-full pr-4 py-4 focus:outline-none focus:shadow-outline bg-white !border-grey-300">
          $ <span>{integerPart || "0"}</span>
          {decimalPart && <span className="text-gray-400">.{decimalPart}</span>}
        </div>
      </div>
    </div>
  );
};

export default NumberDisplay;
