import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import * as Yup from "yup";

import { CurrencyAPI } from "services/currency";
import {
  baseToTargetCurrency,
  getApiCurrencies,
  getCurrencyMappedOptions,
} from "helpers/currency";
import { useFormContext } from "react-hook-form";
import DefaultInput from "components/FormInputs/DefaultInput";
import NumberDisplay from "pages/Profile/Wizard/ProfessionalProfile/Rate/molecules/NumberDisplay";
import { CURRENCY_CONFIG, SALARY_CONFIG } from "lookup";

const currencyApi = new CurrencyAPI(
  CURRENCY_CONFIG.url,
  CURRENCY_CONFIG.apiKey
);

const rateInstructions = {
  default: `Enter your requested hourly rate in US Dollars (USD) for this job. This rate can
be different than the default rate on your profile.`,
  conversion: `Enter your requested hourly rate for this job in your selected currency and it will be converted to US Dollars (USD) automatically. This rate can
be different than the default rate on your profile.`,
};

const Rate = ({
  id,
  currencyInputKeyId,
  schemaShapeRef,
  allowConversion,
  defaultCurrency,
  initialCurrencies,
  useApi,
  isReadOnly,
}) => {
  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(false);

  const { watch, setValue, trigger, getValues } = useFormContext();

  const watchedCurrencyInputValue = watch(currencyInputKeyId);

  const watchedRateValue = watch(id);

  // fetch currencies
  useEffect(() => {
    (async () => {
      setIsLoadingCurrencies(true);

      if (allowConversion && useApi && currencyConfigRef.current) {
        currencyConfigRef.current = {
          ...currencyConfigRef.current,
          currencies: await getApiCurrencies(),
        };
      }

      if (currencyConfigRef.current.currentCurrency.value !== "USD") {
        const newConvertedValue = baseToTargetCurrency(
          watchedRateValue,
          currencyConfigRef.current.currentCurrency.factor
        );

        setValue(currencyInputKeyId, newConvertedValue || "");
      }

      updateSchema();

      setTimeout(() => {
        trigger();
      }, 50);

      setIsLoadingCurrencies(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currencyConfigRef.current.currentCurrency.value === "USD") {
      setValue(id, parseInt(getValues(id)) || "");

      return;
    }

    const newConvertedValue =
      currencyConfigRef.current.currentCurrency.factor *
      watchedCurrencyInputValue;

    setValue(
      id,
      parseInt(Math.ceil(Number(newConvertedValue.toFixed(2)))) || ""
    );
  }, [watchedCurrencyInputValue, id, setValue, getValues]);

  const updateSchema = () => {
    if (!schemaShapeRef?.current) {
      return;
    }

    const { currentCurrency, currencies } = currencyConfigRef.current;

    if (currentCurrency.value !== "USD") {
      let maxValueStr = "";
      let maxValue = SALARY_CONFIG.MAX_RATE_PER_HOUR;

      const currencySymbol = currencies[currentCurrency.value]?.code || "";

      maxValue = parseInt(
        SALARY_CONFIG.MAX_RATE_PER_HOUR / currentCurrency.factor
      );

      maxValueStr = `Enter a value less than or equal to ${currencySymbol} ${maxValue} ($200)`;

      schemaShapeRef.current[currencyInputKeyId] = Yup.number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .max(maxValue, maxValueStr)
        .min(1, `Enter at least ${currencySymbol}1`)
        .required("hourly rate is required");

      delete schemaShapeRef.current[id];
    } else {
      schemaShapeRef.current[id] = Yup.number()
        .transform((value, originalValue) => {
          return originalValue === "" ? undefined : value;
        })
        .max(
          SALARY_CONFIG.MAX_RATE_PER_HOUR,
          "Enter a value less than or equal to $200"
        )
        .min(1, "Enter at least $1")
        .required("hourly rate is required");

      delete schemaShapeRef.current[currencyInputKeyId];
    }
  };

  const handleCurrencyOnChange = async (e) => {
    try {
      let factor = e.factor;

      if (!factor) {
        const { data } = await currencyApi.latest({
          currencies: "USD",
          base_currency: e.value,
        });

        factor = data["USD"].value;
      }

      currencyConfigRef.current.currentCurrency = {
        ...e,
        factor,
      };

      const newConvertedValue = baseToTargetCurrency(watchedRateValue, factor);

      updateSchema();

      setValue(
        currencyInputKeyId,
        newConvertedValue > 0 ? newConvertedValue : ""
      );

      setTimeout(() => {
        trigger();
      }, 50);
    } catch (err) {
      console.log(err);
    }
  };

  const currencyConfigRef = useRef({
    currencies: initialCurrencies ?? {},
    currentCurrency: {
      value: "USD",
      label: `USD (US Dollar)`,
      factor: 1,
      ...defaultCurrency,
    },
  });

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div className="flex items-center gap-x-2">
          <div
            className={`currency-flag currency-flag-${props.data?.value?.toLowerCase()}`}
          />
          {props.label}
        </div>
      </components.Option>
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <div className="flex pl-2 w-full items-center">
          <div
            className={`currency-flag currency-flag-${props
              .getValue()[0]
              .value?.toLowerCase()}`}
          />
          {children}
        </div>
      </components.Control>
    );
  };

  const currentCurrencyValueCode =
    currencyConfigRef.current.currentCurrency.value;

  const instructionsType = allowConversion ? "conversion" : "default";

  return (
    <>
      <div className="text-base font-rubik-bold mt-8 mb-2">Hourly rate</div>
      <p className="text-base font-rubik-regular">
        {rateInstructions[instructionsType]}
      </p>

      <div className="flex flex-col gap-y-6 mt-9">
        {allowConversion && (
          <Select
            className={allowConversion ? "w-3/4 sm:w-[250px]" : "w-3/4"}
            options={getCurrencyMappedOptions(
              currencyConfigRef.current.currencies
            )}
            isLoading={isLoadingCurrencies}
            isDisabled={isLoadingCurrencies}
            value={currencyConfigRef.current.currentCurrency}
            onChange={handleCurrencyOnChange}
            placeholder="Search for currency..."
            styles={{
              control: (base) => ({
                ...base,
                padding: "8px 4px",
                border: "2px solid black !important",
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: "250px",
              }),
            }}
            components={{ Option, Control }}
          />
        )}

        <div className="w-full">
          <div className="flex gap-2 gap-x-2 sm:gap-x-6">
            {currentCurrencyValueCode !== "USD" && (
              <>
                <DefaultInput
                  label=""
                  defaultValue={watchedRateValue}
                  className="min-w-[100px] w-[250px]"
                  type="number"
                  id={currencyInputKeyId}
                  placeholder="0"
                  classOverrides="!placeholder-gray-500"
                  onKeyDown={(evt) =>
                    ["e", "E", "+", "-", "."].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  readOnly={isReadOnly}
                />
                <div className="h-[56px] flex items-center justify-center">
                  <label className="text-2xl font-black">=</label>
                </div>
                <NumberDisplay
                  id={id}
                  className="min-w-[200px] mt-[1px]"
                  number={watchedRateValue}
                  label="Rate per Hour (in USD)"
                />
              </>
            )}

            {currentCurrencyValueCode === "USD" && (
              <DefaultInput
                className={allowConversion ? "w-3/4 sm:w-[250px]" : "w-3/4"}
                type="number"
                id={id}
                label="Rate per Hour (in $)"
                defaultValue={watchedRateValue}
                disabled={currentCurrencyValueCode !== "USD"}
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
                readOnly={isReadOnly}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rate;
