import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Select, { components } from "react-select";
import { isEqual } from "lodash";
import classNames from "classnames";

import DefaultInput from "components/FormInputs/DefaultInput";
import InfoPopover from "components/FormComponentsNew/InfoPopover";

import NumberDisplay from "./molecules/NumberDisplay";

import "currency-flags/dist/currency-flags.min.css";

import { getRateSchema, isValidRate, isValidSalary } from "./helper";
import {
  baseToTargetCurrency,
  getApiCurrencies,
  getCurrencyMappedOptions,
} from "helpers/currency";
import { CurrencyAPI } from "services/currency";
import { CURRENCY_CONFIG } from "lookup";

const currencyApi = new CurrencyAPI(
  CURRENCY_CONFIG.url,
  CURRENCY_CONFIG.apiKey
);

const instructions = {
  default: {
    title: "Your expected compensation in U.S. Dollars (USD)",
    subTitle: "You may enter an hourly rate, a yearly salary, or both.",
    hourlyRate: (
      <>
        Please enter your requested hourly rate in the box.
        <br />
        As a contractor your rate should factor in taxes, vacations, holidays,
        etc.
        <br />
        <span className="font-normal">
          This value is only used to help match you to the right jobs.
          <br />
          You'll be able to adjust this requested rate when you apply to
          specific jobs.
        </span>
      </>
    ),
    yearlyRate: (
      <>
        Please enter your requested yearly salary in the box.
        <br />
        <br />
        <span className="font-normal">
          This value is only used to help match you to the right jobs.
          <br />
          You'll be able to adjust this requested salary when you apply to
          specific jobs.
        </span>
      </>
    ),
  },
  conversion: {
    title: "Your expected compensation",
    subTitle: "You may enter an hourly rate, a yearly salary, or both.",
    hourlyRate: (
      <>
        Please enter your rate in the box in your selected currency.
        <br />
        This will be automatically converted to USD and saved to your profile.
        <br />
        <span className="font-normal">
          This profile rate is only used to help match you to the right jobs.
          <br />
          You'll be able to adjust this requested rate when you apply to
          specific jobs.
        </span>
      </>
    ),
    yearlyRate: (
      <>
        Please enter your yearly salary in the box in your selected currency.
        <br />
        This will be automatically converted to USD and saved to your profile.
        <br />
        <span className="font-normal">
          This profile salary is only used to help match you to the right jobs.
          <br />
          You'll be able to adjust this requested salary when you apply to
          specific jobs.
        </span>
      </>
    ),
  },
};

const renderSectionText = (compensationType, instructionsType) => {
  if (compensationType === "ratePerHour") {
    return (
      <p className="b4-bold mt-4">
        {instructions[instructionsType].hourlyRate}
      </p>
    );
  }

  return (
    <p className="b4-bold mt-4">{instructions[instructionsType].yearlyRate}</p>
  );
};

const getMonthlyRate = (compensationType, value) => {
  if (!compensationType || !value) {
    return "";
  }

  if (compensationType === "ratePerHour") {
    return Math.round(value * 173);
  }

  return Math.round(value / 12);
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center gap-x-2">
        <div
          className={`currency-flag currency-flag-${props.data?.value?.toLowerCase()}`}
        />
        {props.label}
      </div>
    </components.Option>
  );
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex pl-2 w-full items-center">
        <div
          className={`currency-flag currency-flag-${props
            .getValue()[0]
            .value?.toLowerCase()}`}
        />
        {children}
      </div>
    </components.Control>
  );
};

const Rate = ({
  user,
  className,
  rateRef,
  save,
  setDisabled,
  setReminderText,
  allowConversion,
  defaultCurrency,
  initialCurrencies,
  useApi,
}) => {
  const [compensationType, setCompensationType] = useState(() => {
    if (!user?.ratePerHour?.value && !user?.salary?.value) {
      return "ratePerHour";
    } else if (user?.ratePerHour?.value) {
      return "ratePerHour";
    }
    return "salary";
  });

  const [isLoadingCurrencies, setIsLoadingCurrencies] = useState(true);

  const currencyConfigRef = useRef({
    currencies: initialCurrencies ?? {},
    currentCurrency: {
      value: "USD",
      label: `USD (US Dollar)`,
      factor: 1,
      ...defaultCurrency,
    },
  });

  const methods = useForm({
    resolver: yupResolver(
      getRateSchema(currencyConfigRef.current, compensationType)
    ),
    defaultValues: {
      ratePerHour: user?.ratePerHour?.value || "",
      salary: user?.salary?.value || "",
    },
    mode: "all",
  });

  const { getFieldState, watch, setValue, getValues } = methods;

  const watchedRatePerHourValue = watch("ratePerHour");
  const watchedSalaryValue = watch("salary");

  const watchedCurrencyInputValue = watch("currencyInputValue");

  const handleBlur = ({ target }) => {
    const key = target.id;

    if (getFieldState(key).error) {
      return;
    }

    const value = getValues(compensationType) || null;

    if (value > 0 || value === null) {
      const attrObj =
        value > 0 ? { value: parseInt(value), currency: "USD" } : null;

      if (isEqual(attrObj, user?.[compensationType])) {
        return;
      }

      save({ [compensationType]: attrObj }, true);
    }
  };

  useEffect(() => {
    if (user?.ratePerHour?.value || user?.salary?.value) {
      setReminderText("");
    } else {
      setReminderText("Set your compensation");
      setDisabled(true);
    }

    return () => {
      setReminderText("");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setDisabled]);

  useEffect(() => {
    if (!watchedRatePerHourValue && !watchedSalaryValue) {
      setReminderText("Set your compensation");
      setDisabled(true);
      return;
    }

    if (currencyConfigRef.current.currentCurrency.value !== "USD") {
      setDisabled(getFieldState("currencyInputValue").error);
      return;
    }

    if (
      isValidRate(Number(watchedRatePerHourValue)) &&
      isValidSalary(Number(watchedSalaryValue))
    ) {
      setReminderText("");
      setDisabled(false);
      return;
    }

    setReminderText("Set your compensation");
    setDisabled(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedRatePerHourValue, watchedSalaryValue, setDisabled, getFieldState]);

  const handleCompensationTypeChange = (type) => {
    setCompensationType(type);
    setValue("ratePerHour", user?.ratePerHour?.value ?? "", {
      shouldValidate: true,
    });
    setValue("salary", user?.salary?.value ?? "", { shouldValidate: true });

    // we are setting USD currency there is no need to handle value for "currencyInputValue"
    if (currencyConfigRef.current.currentCurrency.value === "USD") {
      return;
    }

    let newConvertedValue = 0;

    if (type === "ratePerHour") {
      newConvertedValue = baseToTargetCurrency(
        user?.ratePerHour?.value,
        currencyConfigRef.current.currentCurrency.factor
      );
    } else {
      newConvertedValue = baseToTargetCurrency(
        user?.salary?.value,
        currencyConfigRef.current.currentCurrency.factor
      );
    }

    setValue("currencyInputValue", newConvertedValue || "");
  };

  // fetch currencies
  useEffect(() => {
    (async () => {
      setIsLoadingCurrencies(true);
      if (allowConversion && useApi && currencyConfigRef.current) {
        currencyConfigRef.current = {
          ...currencyConfigRef.current,
          currencies: await getApiCurrencies(),
        };
      }

      if (currencyConfigRef.current.currentCurrency.value !== "USD") {
        handleCompensationTypeChange(compensationType);
      }

      setIsLoadingCurrencies(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currencyConfigRef.current = {
      ...currencyConfigRef.current,
      currencies: initialCurrencies && initialCurrencies,
      currentCurrency: {
        value: "USD",
        label: `USD (US Dollar)`,
        factor: 1,
        ...defaultCurrency,
      },
    };

    if (currencyConfigRef.current.currentCurrency.value !== "USD") {
      handleCompensationTypeChange(compensationType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCurrency?.value, defaultCurrency?.label, defaultCurrency?.value]);

  const handleCurrencyOnChange = async (e) => {
    try {
      let factor = e.factor;

      if (!factor) {
        const { data } = await currencyApi.latest({
          currencies: "USD",
          base_currency: e.value,
        });

        factor = data["USD"].value;
      }

      currencyConfigRef.current.currentCurrency = {
        ...e,
        factor,
      };

      const newConvertedValue = baseToTargetCurrency(
        getValues(compensationType),
        factor
      );

      setValue("currencyInputValue", newConvertedValue);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (currencyConfigRef.current.currentCurrency.value === "USD") {
      setValue(compensationType, parseInt(getValues(compensationType)) || "");
      return;
    }

    const newConvertedValue =
      currencyConfigRef.current.currentCurrency.factor *
      watchedCurrencyInputValue;

    setValue(
      compensationType,
      parseInt(Math.ceil(Number(newConvertedValue.toFixed(2)))) || ""
    );
  }, [watchedCurrencyInputValue, compensationType, setValue, getValues]);

  const currentCurrencyValueCode =
    currencyConfigRef.current.currentCurrency.value;

  const instructionsType = allowConversion ? "conversion" : "default";

  return (
    <div className={className} ref={rateRef}>
      <div className="b1 mb-2">{instructions[instructionsType].title}</div>

      <div className="font-normal mb-2">
        {instructions[instructionsType].subTitle}
      </div>
      <div className="border border-gray-300 rounded-full text-gray-400 w-fit">
        <span
          className={classNames(
            "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-l-full text-center font-normal inline-block border-r border-gray-300",
            {
              "bg-blue-500 text-white": compensationType === "ratePerHour",
            }
          )}
          onClick={() => handleCompensationTypeChange("ratePerHour")}
        >
          Hourly Rate
        </span>
        <span
          className={classNames(
            "p-2 hover:bg-blue-500 hover:cursor-pointer hover:text-white rounded-r-full text-center font-normal inline-block border-r border-l border-gray-300",
            {
              "bg-blue-500 text-white": compensationType === "salary",
            }
          )}
          onClick={() => handleCompensationTypeChange("salary")}
        >
          Yearly Salary
        </span>
      </div>

      {renderSectionText(compensationType, instructionsType)}
      <FormProvider {...methods}>
        <form onBlur={handleBlur}>
          <div
            className={classNames(
              "flex mt-16 items-start flex-wrap",
              { "gap-x-2 gap-y-6 flex-col": allowConversion },
              { "gap-8": !allowConversion }
            )}
          >
            {allowConversion && (
              <Select
                className="!w-[250px]"
                options={getCurrencyMappedOptions(
                  currencyConfigRef.current.currencies
                )}
                isLoading={isLoadingCurrencies}
                isDisabled={isLoadingCurrencies}
                value={currencyConfigRef.current.currentCurrency}
                onChange={handleCurrencyOnChange}
                placeholder="Search for currency..."
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: "8px 4px",
                    border: "2px solid black !important",
                  }),
                  menuList: (base) => ({
                    ...base,
                    maxHeight: "250px",
                  }),
                }}
                components={{ Option, Control }}
              />
            )}

            <div className="w-full sm:w-fit">
              <div className="flex gap-2 gap-x-2 sm:gap-x-6">
                {currentCurrencyValueCode !== "USD" && (
                  <>
                    <DefaultInput
                      label=""
                      className="min-w-[100px] w-[250px]"
                      type="number"
                      id="currencyInputValue"
                      placeholder="0"
                      classOverrides="!placeholder-gray-500"
                    />
                    <div className="h-[56px] flex items-center justify-center">
                      <label className="text-2xl font-black">=</label>
                    </div>
                    <NumberDisplay
                      className="min-w-[200px] mt-[1px]"
                      number={
                        compensationType === "ratePerHour"
                          ? watchedRatePerHourValue
                          : watchedSalaryValue
                      }
                      label={
                        compensationType === "ratePerHour"
                          ? "Rate per Hour (in USD)"
                          : "Yearly Salary (in USD)"
                      }
                    />
                  </>
                )}

                {currentCurrencyValueCode === "USD" && (
                  <DefaultInput
                    className={
                      allowConversion ? "w-full sm:w-[250px]" : "w-max"
                    }
                    type="number"
                    id={compensationType}
                    label={
                      compensationType === "ratePerHour"
                        ? "Rate per Hour (in $)"
                        : "Yearly Salary (in $)"
                    }
                    value={
                      compensationType === "ratePerHour"
                        ? watchedRatePerHourValue
                        : watchedSalaryValue
                    }
                    disabled={currentCurrencyValueCode !== "USD"}
                  />
                )}
              </div>
            </div>

            {!allowConversion && (
              <div className="flex gap-2 items-center flex-wrap">
                <div className="flex gap-2 items-center">
                  <DefaultInput
                    className="w-max"
                    type="number"
                    id="monthlyRate"
                    label="Monthly Equivalent (in $)"
                    disabled
                    min={1}
                    placeholder="0"
                    value={getMonthlyRate(
                      compensationType,
                      compensationType === "ratePerHour"
                        ? watchedRatePerHourValue
                        : watchedSalaryValue
                    )}
                  />
                  <InfoPopover>
                    Monthly equiv is calculated as
                    <br />
                    hourly rate x 173 or salary / 12
                  </InfoPopover>
                </div>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Rate;
