/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID) {
    onUpdateUser(id: $id) {
      id
      codealike {
        handle
        totalPoints
        usedPoints
      }
    }
  }
`;

export const onUserResumeDataExtraction = /* GraphQL */ `
  subscription OnUserResumeDataExtraction($identity_username: String!) {
    onUserResumeDataExtraction(identity_username: $identity_username) {
      identity_username
      data
    }
  }
`;
