import PrimaryBtn from "components/buttons/Primary";

const Footer = ({
  savingChanges,
  onNext,
  onSkip,
  onBack,
  disabled,
  showBackBtn,
  autoSaved,
  finalStep,
  reminderText,
  step,
}) => {
  let buttonText = "Next";
  if (savingChanges) {
    buttonText = "Saving...";
  } else if (finalStep) {
    buttonText = "Done";
  } else if (step === 0) {
    buttonText = "Continue to Profile";
  }

  return (
    <div className="z-10 fixed bg-white py-4 sm:py-6 bottom-0 w-full shadow-sm">
      <div className="compact-text container-large !py-0 justify-center gap-2 flex flex-col items-end h-full">
        <div className="min-h-[18px] sm:min-h-[28px] text-right">
          {reminderText ? (
            <div className="b3 text-grey-300 flex">{reminderText}</div>
          ) : (
            autoSaved && (
              <div className="b3 text-grey-300 flex">Automatically saved</div>
            )
          )}
        </div>
        <div className="flex justify-end items-center gap-3 sm:gap-10 w-full">
          {showBackBtn && (
            <PrimaryBtn
              label="Back"
              onClick={onBack}
              className="mr-auto !border-functionalSuccess border-2 !min-w-0 sm:!min-w-[134px]"
              color="transparent"
            />
          )}
          <div className="flex gap-8 items-center">
            <div
              className="b3 text-base text-brandSecondary cursor-pointer"
              onClick={onSkip}
            >
              Skip
            </div>
            <div>
              <PrimaryBtn
                label={buttonText}
                loading={savingChanges}
                disabled={disabled}
                onClick={onNext}
                className="!min-w-0 sm:!min-w-[134px]"
              />
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default Footer;
