import React from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import "react-circular-progressbar/dist/styles.css";

import GetStartedBtn from "components/buttons/GetStarted/GetStarted";
import ProfileCompletionWidget from "components/ProfileCompletionWidget";

import styles from "./index.module.css";

export const HeaderSection = ({ appsyncUser, showProfileCompletionWidget }) => {
  const { username, given_name } = appsyncUser;
  const history = useHistory();

  return (
    <section
      className={classNames(
        "flex justify-center",
        "full-width",
        styles["freelancer-dashboard-header"]
      )}
    >
      <div
        className={classNames(
          "flex flex-col-reverse md:flex-row items-center w-full px-6 md:px-0 py-8 md:py-14 justify-between",
          "full-width--wrapper"
        )}
      >
        <div className="pr-14 flex-1">
          <h1 className="lg:text-5xl md:text-3xl text-2xl mb-8">
            Welcome Back, {given_name}
          </h1>

          <div className="flex lg:flex-row flex-col mb-8 w-full">
            <GetStartedBtn
              label="View Profile"
              id="ViewProfile"
              data-cy="ViewProfile"
              className="uppercase w-52"
              textColor="text-black"
              smallButton
              onClick={() => username && history.push(`/profile/${username}`)}
            />
            &nbsp;&nbsp;
            <GetStartedBtn
              label="Edit Profile"
              className="uppercase w-52"
              textColor="text-black"
              smallButton
              onClick={() => username && history.push(`/profile/wizard/0`)}
            />
          </div>
        </div>
        <div
          className={classNames(
            "flex justify-end items-center mb-12 md:mb-0 flex-1"
          )}
        >
          {showProfileCompletionWidget && <ProfileCompletionWidget />}
        </div>
      </div>
    </section>
  );
};
