import * as Yup from "yup";

export const getStandardObject = (overallExperienceEnabled) => {
  return {
    given_name: Yup.string().required("Enter your first name").nullable(),
    family_name: Yup.string().required("Enter your last name").nullable(),
    email: Yup.string().email().required("Enter your email address"),
    location: Yup.object().required("Enter your city, state/province"),
    overallExperience: overallExperienceEnabled
      ? Yup.number()
          .transform((value, originalValue) => {
            return originalValue === "" ? undefined : value;
          })
          .min(1, "Please enter at least 1 year of industry experience.")
          .max(100, "Enter no more than 100 years of industry experience.")
          .required("Please enter your years of industry experience.")
          .test("integer", "Please enter a valid integer.", (value) => {
            if (
              !Number.isInteger(Number(value)) ||
              String(value).includes(".")
            ) {
              return false;
            }

            return true;
          })
      : undefined,
    tagline: Yup.string()
      .required("Enter your tagline")
      .max(60, "Maximum length of a tagline is 60 characters.")
      .nullable(),
    bio: Yup.string()
      .required("Enter your biography")
      .max(1000, "Maximum length of bio is 1000 characters.")
      .nullable(),
    phone: Yup.string().nullable(),
    whatsAppAllowed: Yup.bool().nullable(),
  };
};
